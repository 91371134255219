declare var ENV: string;

const DEVELOPMENT: string = 'development';
const PRODUCTION: string = 'production';
const QUALITY: string = 'quality';
const STAGING: string = 'staging';
const TESTING: string = 'testing';

export class EnvironmentComponent {

    public static isDevelopment(): boolean {
        return ENV === DEVELOPMENT;
    }

    public static isProduction(): boolean {
        return ENV === PRODUCTION;
    }

    public static isQuality(): boolean {
        return ENV === QUALITY;
    }

    public static isStaging(): boolean {
        return ENV === STAGING;
    }

    public static isTesting(): boolean {
        return ENV === TESTING;
    }
}

// export const environment = new EnvironmentComponent();
