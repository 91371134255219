import { Component, componentService, services } from '@contorion/core';

componentService.register('.js-add-to-save-for-later-product', (element: Element) => {
    import (
        /* webpackChunkName: "add-to-save-for-later-product" */
        './components/add-to-save-for-later-product.component')
        .then(({ AddToSaveForLaterProductComponent }) => {
            <Component> AddToSaveForLaterProductComponent.loadByElement(element);
        });
});
componentService.register('.js-remove-from-save-for-later-product', (element: Element) => {
    import (
        /* webpackChunkName: "remove-from-save-for-later-product" */
        './components/remove-from-save-for-later-product.component')
        .then(({ RemoveFromSaveForLaterProductComponent }) => {
            <Component> RemoveFromSaveForLaterProductComponent.loadByElement(element);
        });
});
componentService.register('.js-save-for-later-list', (element: Element) => {
    import (
        /* webpackChunkName: "save-for-later-list" */
        './components/save-for-later-list.component')
        .then(({ SaveForLaterListComponent }) => {
            <Component> SaveForLaterListComponent.loadByElement(element);
        });
});

componentService.register('.js-save-for-later-cart-sync', (element: Element) => {
    import (
        /* webpackChunkName: "save-for-later-cart-synchronizer" */
        './components/save-for-later-cart-synchronizer.component')
        .then(({ SaveForLaterCartSynchronizerComponent }) => {
            <Component> SaveForLaterCartSynchronizerComponent.loadByElement(element);
        });
});

services.register('save-for-later-service', () => {
    return import (
        /* webpackChunkName: "save-for-later-service" */
        './services/save-for-later.service')
        .then(({ saveForLaterService }) => {
            return saveForLaterService;
        });
});
