import { Component, componentService, services } from '@contorion/core';
componentService.register('.js-copy-code', (ele: Element) => {
    import (
        /* webpackChunkName: "copy-code" */
        './components/copy-code.component')
        .then(({ CopyCodeComponent }) => {
            <Component> CopyCodeComponent.loadByElement(ele);
        });
});
componentService.register('.js-loading-indicator', (ele: Element) => {
    import (
        /* webpackChunkName: "loading-indicator" */
        './components/loading-indicator.component')
        .then(({ LoadingIndicatorComponent }) => {
            <Component> LoadingIndicatorComponent.loadByElement(ele);
        });
});
services.register('loading-service', () => {
    return import (/* webpackChunkName: "loading-service"*/
        './services/loading.service')
        .then(({loadingService}) => {
            return loadingService;
        });
});
