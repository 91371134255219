import {Component, componentService, services} from '@contorion/core';

componentService.register('.js-add-to-cart', (element: Element) => {
    import (
        /* webpackChunkName: "add-to-cart-form" */
        './components/add-to-cart-form.component')
        .then(({ AddToCartFormComponent }) => {
            <Component> AddToCartFormComponent.loadByElement(element);
        });
});

componentService.register('.js-cart-quantity-form', (element: Element) => {
    import (
        /* webpackChunkName: "cart-quantity-form" */
        './components/cart-quantity-form.component')
        .then(({ CartQuantityFormComponent }) => {
            <Component> CartQuantityFormComponent.loadByElement(element);
        });
});

componentService.register('.js-cart-query-param-remover', (element: Element) => {
    import (
        /* webpackChunkName: "cart-query-param-remover" */
        './components/cart-query-param-remover.component')
        .then(({ CartQueryParamRemoverComponent}) => {
            <Component> CartQueryParamRemoverComponent.loadByElement(element);
        });
});

componentService.register('.js-cart-v2-item', (element: Element) => {
    import (
        /* webpackChunkName: "cart-v2-item" */
        './components/cart-v2-item.component')
        .then(({ CartV2ItemComponent }) => {
            <Component> CartV2ItemComponent.loadByElement(element);
        });
});

services.register('cart-service', () => {
    return import (
        /* webpackChunkName: "cart-service" */
        './services/cart.service')
        .then(({ cartService }) => {
            return cartService;
        });
});

services.register('cart-loading-service', () => {
    return import (
        /* webpackChunkName: "cart-loading-service" */
        './services/cart-loading.service')
        .then(({ cartLoadingService }) => {
            return cartLoadingService;
        });
});
